<template>
    <div>
        <a-layout>
            <a-layout-sider class="layout-left-sider" style="overflow: hidden;" :width="widthNum">
                <div class="nav">
                    <div class="nav-one">
                        <div class="logo">
                            <img v-if="siteInfo != null && siteInfo.logo_round!=null" :src="siteInfo.logo_round"/>
                            <img v-else src="../assets/image/mall_home_pintuan@3x.png" class="member-index-face"/>
                        </div>
                        <div class="menu-list-box">
                            <div class="menu-list">
                                <div class="menu-item" v-for="(item,index) in  menus" :key="index"
                                     @click="menuAct(index)"
                                     :class="item.navOneClaOn">
                                    <div class="text-center">
                                        <div class="iconfont ft24" :class="item.icon"></div>
                                        <div class="mt8">{{ item.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="bottom">
                            <div class="text-center mt20">
                                <a-popover placement="right">
                                    <template slot="content">
                                        <div class="manage-action-popover">
                                            <div class="item" @click="changeSite()"
                                                 v-if="client_env=='shop' && mendian_count>0">
                                                <i class="iconfont icontabbar13 ft14 mr10"></i>
                                                <span class="ft14">切换站点</span>
                                            </div>
                                            <div class="item" @click="LogOut()">
                                                <i class="iconfont iconquit ft14 mr10"></i>
                                                <span class="ft14">退出管理</span>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="bot-tit">
                                        <i class="iconfont iconrecord ft14 mr10"></i>
                                        <span class="ft14">系统</span>
                                    </div>
                                </a-popover>
                            </div>
                        </div>

                    </div>

                    <div class="nav-two" v-for="(item,index) in  menus" :key="index" v-if="item.navTwoShow">
                        <a-layout>
                            <a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="160px">
                                <div style="padding: 20px 0px;">
                                    <div v-for="(item2,index2) in item.children" :key="index2"
                                         @click="goToUrl(index,index2)" class="second-menu-item "
                                         :class="item2.navTwoClaOn">
                                        <i class="iconfont ft14" :class="item2.icon"></i>
                                        <span class="ft14 ml10">{{ item2.title }}</span>
                                    </div>
                                </div>
                            </a-layout-sider>
                        </a-layout>

                    </div>
                </div>
            </a-layout-sider>

            <a-layout-content style="height: 100vh; background: #F7F8FA;overflow-x:scroll">
              <router-view></router-view>
            </a-layout-content>

        </a-layout>

        <div v-if="showShops">
            <shops-from :visible="showShops" :shopData="shopList" @cancel="cancelFrom"></shops-from>
        </div>

    </div>
</template>

<script>
import shopsFrom from '../components/shops.vue';

export default {
    components: {
        shopsFrom
    },
    data() {
        return {
            showShops: false,
            siteInfo: null,
            showCourse: true,
            menus: [],
            shopList: [],
            mendian_count: 0,
            widthNum: '80px',
            client_env: localStorage.getItem('client-env-platform')
        }
    },
    created() {
        this.getPagesInit();
        this.getShops();
    },
    methods: {
        menuAct(index) {
            let t = this, data = t.menus[index], res = t.menus;
            if (data.routing_key != t.getSelectKey) {
                t.$router.push(data.routing_path).catch();
                res.forEach(function (e, i) {
                    res[i]['navTwoShow'] = false;
                    res[i]['navOneClaOn'] = '';
                    res[i]['children'].forEach(function (ee, ii) {
                        res[i]['children'][ii]['navTwoClaOn'] = '';
                    });

                    if (i == index) {
                        res[index]['navOneClaOn'] = 'on';
                        if (e.children.length <= 0) {
                            t.widthNum = '80px';
                            res[index]['navTwoShow'] = false;
                        } else {
                            res[index]['children'][0]['navTwoClaOn'] = 'on';
                            res[index]['navTwoShow'] = true;
                            t.widthNum = '240px';
                        }
                    }
                })
                t.menus = res;
            }
        },
        goToUrl(index, index2) {
            let t = this, res = t.menus, path = t.menus[index]['children'][index2]['routing_path'];
            t.refreshPage(path)
            res.forEach(function (e, i) {
                res[i]['children'].forEach(function (ee, ii) {
                    res[i]['children'][ii]['navTwoClaOn'] = '';
                    if (i == index && ii == index2) {
                        res[index]['children'][index2]['navTwoClaOn'] = 'on';
                    }
                });
            });
            t.menus = res;
        },
        LogOut() {
            localStorage.setItem('auth-token', '');
            localStorage.setItem('client-site-info', '');
            localStorage.setItem('userinfo', '');
            this.$message.success('已退出此账号');
            this.$router.push('/login?type=' + localStorage.getItem('client-env-platform'));
        },
        changeSite() {
            this.showShops = true;
        },
        getPagesInit() {
            //初始化加载不同端的页面
            let t = this;
            let uri = this.$route.fullPath;
            t.$http.api('getPages').then(res => {
                res.forEach(function (e, i) {
                    //一级目录
                    if (e.routing_path === uri) {
                        res[i]['navOneClaOn'] = 'on';
                        if (e.children.length <= 0) {
                            t.widthNum = '80px';
                            res[i]['navTwoShow'] = false;
                        } else {
                            res[i]['children'][0]['navTwoClaOn'] = 'on';
                            res[i]['navTwoShow'] = true;
                            t.widthNum = '240px';
                        }
                    } else {
                        var isOne = false;
                        if (e.children.length > 0) {
                            e.children.forEach(function (item, key) {
                                if (item.routing_path === uri) {
                                    res[i]['children'][key]['navTwoClaOn'] = 'on';
                                    isOne = true;
                                }
                            })
                        }
                        if(isOne){
                            res[i]['navOneClaOn'] = 'on';
                            if (e.children.length <= 0) {
                                t.widthNum = '80px';
                                res[i]['navTwoShow'] = false;
                            } else {
                                res[i]['navTwoShow'] = true;
                                t.widthNum = '240px';
                            }
                        }else{
                            res[i]['navOneClaOn'] = '';
                            res[i]['navTwoShow'] = false;
                        }

                    }
                })
                t.menus = res;
            }).catch(res => {

            });
        },
        getShops() {
            let t = this;
            if (t.client_env != 'shop') {
                return;
            }

            t.$http.api('admin/getAccountShops').then(res => {
                t.mendian_count = res.mendian_count;
                t.shopList = res.mendian;
            }).catch(res => {

            });
        },
        cancelFrom() {
            this.showShops = false;
        }
    }
}
</script>

<style>
.member-index-face {
  border-radius: 44px;
}
.nav {
    background: #fff;
}
.menu-list-box{
  height: 100%;padding-bottom:140px;overflow-y: scroll;
}
.nav-one {
    float: left;
    background: #3D475D;
    z-index: 10;
    flex: 0 0 80px;
    max-width: 80px;
    min-width: 80px;
    width: 80px;
    height: 100vh;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.06);
}

.nav-two {
    float: right;
    lex: 0 0 160px;
    max-width: 160px;
    min-width: 160px;
    width: 160px;
    background: #fff;
    height: 100vh;
}

.layout-left-sider .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100%;
    line-height: 1;

}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar-thumb {
    background: rgb(0 0 0 / 15%);;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 15%);
}

.layout-left-sider .ant-layout-sider-children ::-webkit-scrollbar-track {
    background: rgb(255 255 255 / 15%);
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 5px rgb(255 255 255 / 15%);
}

.layout-left-sider .logo {
    padding: 15px;
    position: relative;
    display: flex;
    align-items: center;
}

.layout-left-sider .logo img {
    width: 48px;
    height: 48px;
}

.layout-left-sider .bottom {
    color: #9198A9;
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 80px;
    line-height: 50px;
    text-align: center;
    background: #3D445D;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.layout-left-sider .bottom .bot-tit {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 80px;
    line-height: 50px;
    text-align: center;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.layout-left-sider .menu-list {
    padding: 0 6px;
    background: #3D475D;
}

.layout-left-sider .menu-item {
    width: 68px;
    height:68px;
    background: #3D475D;
    border-radius: 8px;
    color: #9198A9;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.layout-left-sider .menu-item.on {
    background: #4772FF;
    color: #FFFFFF;
}

.layout-left-sider .notice-icon {
    text-align: center;
    cursor: pointer;
}

.add-action-popover {
    padding: 12px 16px;
}

.add-action-popover .item {
    width: 160px;
    height: 48px;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    align-items: center;
    color: #656A75;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-action-popover .item:hover {
    color: #567AF0;
    background: rgba(86, 122, 240, 0.06);
}

.manage-action-popover {
    padding: 12px 16px;
}

.manage-action-popover .item {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #656A75;
    cursor: pointer;
    transition: background-color 0.3s;
}

.manage-action-popover .item:hover {
    color: #567AF0;
    background: rgba(86, 122, 240, 0.06);
}
</style>
